import React from "react";

const GetInTouchIcon: React.FC = () => <>
  <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_4008_27131" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="46" height="45">
  <path d="M0 0.00984573H46V44.418H0V0.00984573Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_4008_27131)">
  <path d="M4.32812 42.5L16.1061 31.1296" stroke="#A8C1B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M41.6755 42.5L29.7617 30.9985" stroke="#A8C1B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M27.043 43.5508H39.0363C41.096 43.5508 42.7656 41.9389 42.7656 39.9505V19.8393C42.7656 18.8848 42.373 17.9694 41.6741 17.2943L38.4459 14.1764" stroke="#A8C1B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.5254 14.2137L4.32741 17.294C3.62753 17.9693 3.23438 18.8853 3.23438 19.8406V39.9504C3.23438 41.9388 4.90403 43.5508 6.9637 43.5508H18.9569" stroke="#A8C1B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M29.5277 5.5625L24.6848 0.884468C23.3778 0.884034 22.6449 0.883861 21.3379 0.883427L16.4883 5.56241" stroke="#A8C1B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M42.3951 18.7072L25.6505 34.8823C24.1945 36.2887 21.8332 36.2893 20.3764 34.8837L3.67188 18.7671" stroke="#A8C1B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M24.0858 21.9064H22.6565C20.562 21.9064 18.8642 20.2672 18.8642 18.2452C18.8642 16.2233 20.562 14.584 22.6565 14.584C24.6241 14.584 26.2191 16.1238 26.2191 18.0233V20.031C26.2191 21.1942 27.1959 22.1372 28.4009 22.1372C29.6058 22.1372 30.5826 21.1942 30.5826 20.031V18.2536C30.5826 14.2098 27.187 10.9318 22.9983 10.9318C18.8096 10.9318 15.4141 14.2098 15.4141 18.2536C15.4141 22.3591 18.8616 25.6875 23.1145 25.6875H28.575" stroke="#A8C1B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.54688 22.5V8.16494C7.54688 6.72783 8.75357 5.5629 10.2422 5.5629H35.7578C37.2464 5.5629 38.4531 6.72783 38.4531 8.16494V22.2159" stroke="#A8C1B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <path d="M23.3066 42.8047C22.9091 42.8019 22.5849 43.1107 22.5821 43.4937C22.5792 43.8767 22.8983 44.1897 23.2958 44.1925C23.6925 44.1952 24.0167 43.8872 24.0196 43.5041C24.0224 43.1211 23.7033 42.8082 23.3066 42.8047Z" fill="#A8C1B3"/>
  </svg>
</>

export default GetInTouchIcon;
