import React from "react";

const LinkedInWhiteIcon: React.FC = () => <>
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.77475 2.30251C4.77475 3.50451 3.82671 4.47727 2.34415 4.47727C0.919355 4.47727 -0.0286772 3.50451 0.000662254 2.30251C-0.0286772 1.04209 0.919332 0.0976562 2.37255 0.0976562C3.82669 0.0976562 4.74633 1.04209 4.77475 2.30251ZM0.119858 20.1963V6.1953H4.62712V20.1954H0.119858V20.1963Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.24029 10.6634C8.24029 8.91703 8.18069 7.42824 8.12109 6.19703H12.0361L12.2442 8.11511H12.3331C12.9263 7.22732 14.4089 5.88281 16.8111 5.88281C19.7762 5.88281 22.0005 7.77256 22.0005 11.8937V20.198H17.4932V12.439C17.4932 10.6342 16.8413 9.40385 15.2102 9.40385C13.9642 9.40385 13.2234 10.2341 12.9273 11.0351C12.8081 11.3219 12.7494 11.722 12.7494 12.1238V20.198H8.24211V10.6634H8.24029Z" fill="white"/>
  </svg>
</>

export default LinkedInWhiteIcon;
