import React from "react";

const LocationIcon: React.FC = () => <>
  <svg width="32" height="47" viewBox="0 0 32 47" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.193 3.70706C15.6757 3.70344 15.2538 4.10535 15.25 4.60389C15.2463 5.10243 15.6617 5.50975 16.179 5.51336C16.6954 5.51698 17.1173 5.11598 17.1211 4.61743C17.1248 4.11889 16.7095 3.71157 16.193 3.70706Z" fill="#A8C1B3"/>
  <path d="M16.1448 9.12126C12.5326 9.09651 9.57438 11.9097 9.54706 15.3958C9.51984 18.8817 12.4355 21.739 16.0466 21.7652C16.0633 21.7653 16.08 21.7654 16.0967 21.7654C19.6847 21.7654 22.6172 18.9606 22.6443 15.4907C22.6715 12.0049 19.7559 9.14754 16.1448 9.12126ZM16.0965 19.9592C16.0847 19.9592 16.0724 19.9591 16.0606 19.959C13.4812 19.9402 11.3986 17.8993 11.418 15.4094C11.4374 12.9307 13.5319 10.9273 16.0948 10.9273C16.1066 10.9273 16.119 10.9274 16.1308 10.9275C18.7101 10.9463 20.7928 12.9872 20.7733 15.4771C20.7539 17.9558 18.6595 19.9592 16.0965 19.9592Z" fill="#A8C1B3"/>
  <path d="M20.1781 4.39201C19.6914 4.22529 19.1564 4.4714 18.9837 4.94158C18.8111 5.41177 19.066 5.92792 19.553 6.09464C23.6737 7.50456 26.4199 11.2934 26.387 15.5226C26.3832 16.0213 26.7988 16.4287 27.3155 16.4325C27.3178 16.4325 27.3203 16.4325 27.3227 16.4325C27.8359 16.4325 28.2541 16.0326 28.258 15.5361C28.297 10.5374 25.0498 6.05897 20.1781 4.39201Z" fill="#A8C1B3"/>
  <path d="M21.8359 34.0923C28.0584 26.3599 31.9471 22.3278 31.9995 15.5628C32.0654 7.0512 24.9103 0.09375 16.0942 0.09375C7.38111 0.09375 0.258177 6.90606 0.191942 15.3325C0.138429 22.2809 4.09936 26.3075 10.3648 34.091C4.13183 34.9902 0.191942 37.2496 0.191942 40.0132C0.191942 41.8644 1.96439 43.5256 5.18289 44.6906C8.11232 45.7511 11.9879 46.3352 16.0957 46.3352C20.2036 46.3352 24.0792 45.7511 27.0086 44.6906C30.2271 43.5255 31.9995 41.8643 31.9995 40.0131C31.9995 37.2511 28.0633 34.9922 21.8359 34.0923ZM2.06291 15.3461C2.12128 7.91055 8.4057 1.90006 16.0944 1.90006C23.8742 1.90006 30.1866 8.04043 30.1286 15.5493C30.0788 21.9738 25.9548 25.933 19.3708 34.2188C18.1965 35.696 17.118 37.0927 16.0971 38.4589C15.0793 37.0918 14.0223 35.7202 12.8304 34.2183C5.97426 25.5856 2.0122 21.9256 2.06291 15.3461ZM16.0957 44.529C8.06414 44.529 2.06291 42.1449 2.06291 40.0132C2.06291 38.4323 5.65047 36.4352 11.6826 35.7437C13.016 37.4327 14.185 38.9671 15.3316 40.5343C15.5067 40.7736 15.7913 40.9162 16.0949 40.9163C16.0952 40.9163 16.0955 40.9163 16.0957 40.9163C16.399 40.9163 16.6835 40.7744 16.8589 40.5355C17.9947 38.9887 19.1957 37.4164 20.5177 35.7447C26.5442 36.437 30.1286 38.4334 30.1286 40.0133C30.1285 42.1449 24.1274 44.529 16.0957 44.529Z" fill="#A8C1B3"/>
  </svg>
</>

export default LocationIcon;
